import React, { useState } from "react";
import { updateTeam } from "../../util/db";

const TeamEditModal = ({ team, memberPairs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [members, setMembers] = useState(team.members);
  const [newMember, setNewMember] = useState("");

  const getEmailFromUid = (uid, emailUidPairs) => {
    if (!uid || !emailUidPairs) {
      console.error("Invalid UID or email-UID pairs object.");
      return null;
    }
  
    for (const [email, currentUid] of Object.entries(emailUidPairs)) {
      if (currentUid === uid) {
        return email;
      }
    }
  
    return null; // Return null if UID not found
  };

  const getId = (email) => {
    if (memberPairs[email]) {
        return memberPairs[email]
    } else {
        console.log("user not found")
    }

}

    const handleAddMember = (uid) => {
    if (uid && uid.trim() && !members.includes(uid.trim())) {
      setMembers([...members, uid.trim()]);
      setNewMember("");
    }
  };

  const handleSave = () => {
    updateTeam(team.id, {members: members})
    setIsOpen(false)
  };

  return (
    <div className="w-full max-w-md mx-auto">
        <button onClick={() => setIsOpen(!isOpen)} className="border border-green-700 rounded-full px-2">Team</button>

      {isOpen && (
        <div className="mt-4 border rounded shadow p-4 bg-white">
          <div>
            <h3 className="font-semibold mb-2">Team: {team.name}</h3>
            <h4 className="font-semibold mb-2">Members:</h4>
            <ul className="list-disc list-inside mb-4">
              {members.map((member, index) => (
                <li key={index}>{getEmailFromUid(member, memberPairs)}</li>
              ))}
            </ul>
            <div className="flex gap-2 items-center mb-4">
              <input
                type="text"
                className="border rounded px-2 py-1 w-full"
                value={newMember}
                onChange={(e) => setNewMember(e.target.value)}
                placeholder="Add new member UID"
              />
              <button
                className="px-4 py-1 bg-gray-300 rounded hover:bg-gray-400"
                onClick={() => handleAddMember(getId(newMember))}
              >
                Find Member
              </button>
            </div>
            <button
              className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamEditModal;
