export default function LifePackages() {
    return (
        <div className="bg-white py-12 sm:py-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <p className="text-base/7 font-semibold text-green-600">All-Access Lifetime Member Package</p>
                    <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                        Pricing and Features
                    </h1>
                    <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-1">
                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">Core Offer and Features</h2>
                            <ul className="mt-4 space-y-4 text-left ">
                                <li><strong>Stairs And Buckets:</strong> All foundational sales tools.</li>
                                <li><strong>All Seven Sales Processes:</strong> Stairs and Buckets Sales Process, Medicare 101 Sales Process, Large Premium Life with FCB Rider Sales Process, Finding the Money Process, Protect and Participate Process, Understanding Money Process, Liability Wall Process.</li>
                                <li><strong>Agency Training Manual:</strong> A gold-standard training resource.</li>
                                <li><strong>Staff Handbook:</strong> Comprehensive guide for policies, onboarding, and management.</li>
                                <li><strong>Non-Compete Clause Template:</strong> A legally crafted document to protect your business interests.</li>
                                <li><strong>Two Books:</strong> "Paychecks Never Lie" and "BIGGER PAYCHECK$, BETTER PAYCHECK$"</li>
                                <li><strong>Bonus Process:</strong> Service to Sales: Converts service opportunities into sales.</li>
                                <li><strong>Live Weekly Training Calls:</strong> 52 training calls per year, Mondays at 12:30 MST.</li>
                                <li><strong>Exclusive Wyoming Study Group Event:</strong> Annual two-day event for professional growth.</li>
                                <li>ver <strong>Lifetime Study Group Community:</strong> Access to an active and engaged network of professionals.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none my-8">
                    <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                        Exclusive Wyoming Study Group Event
                    </h1>
                    <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Annual Two-Day Event:</strong> Access to an exclusive in-person study group held the third week of June each year. The 2025 event is scheduled for June 17th and 18th.</li>
                                <li><strong>Location:</strong> Held in the beautiful Rocky Mountains, south of Yellowstone and Jackson Hole, offering attendees the perfect opportunity to combine professional growth with personal relaxation and exploration.</li>
                                <li><strong>Expert Speakers:</strong> Features guest speakers and trainers who are industry leaders, delivering actionable insights and transformative content over two days.</li>
                                <li><strong>High Value:</strong> Past attendees consistently report that the event alone is worth the cost of the package, with many seeing a significant return on investment immediately.</li>
                                <li><strong>Limited Seats:</strong> Attendance is capped to maintain an intimate and impactful experience, ensuring direct interaction with speakers and peers.</li>
                                <li><strong>Family-Friendly Destination:</strong> A great chance to turn the event into a family road trip, exploring Grand Teton National Park, Yellowstone National Park, and other iconic locations.</li>
                            </ul>
                        </div>

                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">Lifetime Study Group Community</h2>
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Stay Ahead of Industry Trends:</strong> Lifetime access to an active study group that provides real-time discussions on recent industry changes, trends, and announcements.</li>
                                <li><strong>Continuous Collaboration:</strong> A highly engaged network of like-minded professionals sharing ideas and strategies for success.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none my-8">
                    <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                        Why Choose the All-Access Lifetime Member Package?
                    </h1>
                    <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base/7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <ul className="mt-4 space-y-4 text-start">
                                <li><strong>Lifetime Value:</strong> A one-time payment that includes all current and future training, with no additional costs.</li>
                                <li><strong>Comprehensive Access:</strong> Combines the features of the Stairs and Buckets Package and Agency Training Package, plus exclusive benefits.</li>
                                <li><strong>Unparalleled Training Opportunities:</strong> Attend the prestigious Wyoming Study Group event annually for cutting-edge training and networking.</li>
                                <li><strong>Immediate ROI:</strong> Agents consistently see measurable returns on their investment through improved skills, sales, and networking opportunities.</li>
                                <li><strong>Live Trainings:</strong> Weekly live trainings build to help you master each tool that we offer. Access them live, or watch each recording uploaded after every session.</li>
                            </ul>
                        </div>

                        <div className="border border-green-700 rounded-lg p-6 shadow-lg">
                            <h2 className="text-2xl font-bold tracking-tight text-green-800">Final Invitation</h2>
                            <p className="mt-4 text-start">
                                This package includes our Stairs and Buckets video, the easiest and best-kept secret for selling life insurance in the industry. Our seven sales scripts, recorded trainings, and sales tools have the potential take your agency from earning $100,000 to over $1 million in the next year. We have seen it.
                            </p>
                            <p className="mt-4 text-start">
                                Join us for 52 training webinars a year, where every Monday we model our seven sales processes to sell large life pieces and Medicare sales. Finally, don’t miss out on the exclusive Wyoming Study Group held the third week of June each year. Past attendees report earning tens of thousands of dollars from what they learned at this meeting alone. Take this trip of a lifetime, just a short drive from Yellowstone National Park, and invest in yourself today.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
