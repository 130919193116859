import React, { useState, useEffect } from "react";
import SideNav from "./SideNav";
import LargeCard from "./LargeCard";
import { useAllUsers, useUnapprovedTestimonials, deleteTestimonial, updateTestimonial, useAllTeams } from "../../util/db";
import CSVExporter from "./CSVExporter";
import { Menu } from '@headlessui/react'
import { UserCircleIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import LoadingIcon from "../LoadingIcon";
import { getStripePriceId } from "../../util/prices";
import { useAuth } from "../../util/auth";
import PasswordUpdateButton from "./PasswordUpdateButton";
import EmailUpdateButton from "./EmailUpdateButton";
import TeamEditModal from "./TeamEditModal";

const columns = ["Tools", "Name", "Email", "Phone Number", "Company", "Subscription(s)"]
const allArray = [
  getStripePriceId("sbm"),
  getStripePriceId("sby"),
  getStripePriceId("sbm1"),
  getStripePriceId("tp"),
  getStripePriceId("tpm"),
  getStripePriceId("tpl"),
  getStripePriceId("proc"),
  getStripePriceId("procm"),
  getStripePriceId("life"),
]
const legacyAllArray = ["life", "tp", "proc", "sby", "sbm"]

function CRMSection(props) {
  const auth = useAuth()

  function returnTeamModal(uid, teamsx, teamsStatusx, memberPairs) {
    if (teamsStatusx === "success" && teamsx) {
      const teamsArray = teamsx.filter((each) => each.owner === uid)

      return <>{teamsArray.map((each, k) => <TeamEditModal key={k} team =  {each} memberPairs={memberPairs}/>)}</>
      
    } else {
      return <></>
    }
  }
  function toggleElementsInList(list, elements) {
    let result = [...list]; // Work with a copy of the original list
  
    for (const element of elements) {
      const index = result.indexOf(element);
      if (index === -1) {
        // Element not found, add it
        result = [...result, element];
      } else {
        // Element found, remove it
        result = [...result.slice(0, index), ...result.slice(index + 1)];
      }
    }
  
    return result;
  }
  function haveCommonElement(arr1, arr2) {
    // Convert the first array into a set for O(1) lookups
    const set1 = new Set(arr1);
  
    // Check each element in arr2 against the set
    for (const element of arr2) {
      if (set1.has(element)) {
        return true;
      }
    }
  
    return false;
  }

  function completeFilter(people, plans, legacyPlans) {
    // Both arrays have values
    if (plans.length > 0 && legacyPlans.length > 0) {
      // Neither array contains "extraneous"
      if (!plans.includes("extraneous") && !legacyPlans.includes("extraneous")) {
        return people.filter(
          (each) =>
            (each.stripePriceIds && haveCommonElement(each.stripePriceIds, plans)) ||
            (each.legacyPlans && haveCommonElement(each.legacyPlans, legacyPlans))
        );
      } else {
        // At least one array contains "extraneous"
        const allArray = [...plans, ...legacyPlans];
        return people.filter(
          (each) =>
            (
              each.stripePriceIds &&
              each.stripePriceIds.length > 0 &&
              !haveCommonElement(each.stripePriceIds, allArray)
            ) ||
            (
              each.legacyPlans &&
              each.legacyPlans.length > 0 &&
              !haveCommonElement(each.legacyPlans, allArray)
            )
        );
      }
    }
    // Only plans array has values
    else if (plans.length > 0 && legacyPlans.length === 0) {
      if (!plans.includes("extraneous")) {
        // Include those who have common elements with plans
        return people.filter(
          (each) =>
            each.stripePriceIds &&
            each.stripePriceIds.length > 0 &&
            haveCommonElement(each.stripePriceIds, plans)
        );
      } else {
        // "extraneous" in plans: exclude those who have common elements with plans
        return people.filter(
          (each) =>
            each.stripePriceIds &&
            each.stripePriceIds.length > 0 &&
            !haveCommonElement(each.stripePriceIds, allArray)
        );
      }
    }
    // Only legacyPlans array has values
    else if (legacyPlans.length > 0 && plans.length === 0) {
      if (!legacyPlans.includes("extraneous")) {
        // Include those who have common elements with legacyPlans
        return people.filter(
          (each) =>
            each.legacyPlans &&
            each.legacyPlans.length > 0 &&
            haveCommonElement(each.legacyPlans, legacyPlans)
        );
      } else {
        // "extraneous" in legacyPlans: exclude those who have common elements with legacyPlans
        return people.filter(
          (each) =>
            each.legacyPlans &&
            each.legacyPlans.length > 0 &&
            !haveCommonElement(each.legacyPlans, legacyAllArray)
        );
      }
    }
    // Both arrays are empty
    else {
      // No criteria, return all people or implement desired logic
      return people;
    }
  }

  function findConflatedSubscriptions(people) {
    setPlanFilters([])
    setLegacyPlanFilters([])
      return people.filter((each) => (each.legacyPlans && each.legacyPlans?.length >0 && each.stripePriceIds && each.stripePriceIds?.length >0))
  }

  function findNonMigratedUsers(people) {
    setPlanFilters([])
    setLegacyPlanFilters([])
      return people.filter((each) => (each.importResolutionRequired === true))
  }


  const [planFilters, setPlanFilters] = useState([])
  const [legacyPlanFilters, setLegacyPlanFilters] = useState([])
  const [legacyPlanOpen, setLegacyPlanOpen] = useState(false)
  const [planOpen, setPlanOpen] = useState(false)
  const [filteredPeople, setFilteredPeople] = useState(false)
  const [emailUidPairs, setEmailUidPairs] = useState({});

  const extractEmailUidPairs = (data) => {
    if (!Array.isArray(data)) {
      console.error("Input must be an array of JSON objects");
      return {};
    }

    return data.reduce((acc, item) => {
      if (item.email && item.id) {
        acc[item.email] = item.id;
      }
      return acc;
    }, {});
  };

  const {
    data: testimonials,
    status: testimonialsStatus,
    error: testimonialsError
  } = useUnapprovedTestimonials()

  const {
    data: teams,
    status: teamsStatus,
    error: teamsError
  } = useAllTeams()

  const {data: people,
    status: peopleStatus,
    error: peopleError,
  } = useAllUsers()


//   const peopleStatus = "success"
//   const people = [{
//     "id": "024huWtGPmcnoGdIldWoaxOchP53",
//     "legacyInterval": "year",
//     "stripeCustomerId": "cus_Q6gX6QK6RCweaS",
//     "stripePriceIds": ["price_1Pm0tdDfbx1GFSizzdQiRiKN"],
//     "email": "trevor@insurewithtink.com",
//     "importResolutionRequired": true,
//     "legacyPlans": [
//         "sby"
//     ],
//     "legacyRecentPayment": {
//         "seconds": 1728274946,
//         "nanoseconds": 798000000
//     }
// }]

useEffect(() => {
  if (people && people.length > 0) {
    const result = extractEmailUidPairs(people);
    // Only update state if result is different from current state
    if (JSON.stringify(result) !== JSON.stringify(emailUidPairs)) {
      setEmailUidPairs(result);
    }
  }
}, [people, emailUidPairs]); // Dependency array ensures it runs when jsonArray changes


  return (
    <>
      <div className="container">
      <div className="flex w-full">

        <div className="flex-grow px-4">
          <>
          {(testimonials && testimonialsStatus === "success" && testimonials.length >0)&&
          <LargeCard titleDescriptions  ={[{title: "Testimonials Awaiting Approval", description: "Approve testimonials before they go live."}]}>
        {(testimonials && testimonialsStatus === "success" && testimonials.length>0)?testimonials.map((testimonial, index) => (
            <>
            <div className="flex flex-row items-center">
              <div className="flex flex-col">
                <button onClick={() => updateTestimonial(testimonial.id, {approved: true})} className="bg-green-700 text-white h-min m-2 p-2 rounded-lg">Approve Testimonial</button>
                <button onClick={() => deleteTestimonial(testimonial.id)} className="bg-red-700 text-white h-min m-2 p-2 rounded-lg">Delete Testimonial</button>
              </div>
            {(testimonial.type === "text")&&
            <div
              className="flex-1 border rounded-md border-green-700 relative min-w-[300px] max-w-full"
              key={index}
            >
              <div className="absolute top-0 right-0 text-8xl mt-1 mr-2 text-green-700 opacity-75 font-serif">
                “
              </div>
              <div className="px-4 pt-14 pb-6 sm:px-6 sm:pb-6 relative">
                <blockquote>
                <p className="leading-7 mb-2 font-bold">{testimonial.title}</p>
                  <p className="leading-7 mb-5">{testimonial.description}</p>
                  <footer className="flex items-center space-x-4">
                    <UserCircleIcon className="h-16" />
                    <div>
                      <span className="font-semibold text-green-700">
                        {testimonial.display_name}
                      </span>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            }
            </div>

            {(testimonial.type === "video")&&<div>This would be a video</div>}
            </>
          )):
          <div className="flex w-full justify-center items-center">
          {(testimonialsStatus !== "success")&&<LoadingIcon className="h-12"/>}
          </div>
          }
        </LargeCard>}
        <div className="flex w-full pt-2">
        <LargeCard titleDescriptions = {[{title: "Customers", description: "Here you can view and manage our customers..."}]}>
        {(peopleStatus === "success" && people && people.length >0)&& (
        <div className="p-1 divide-solid divide-y">
          <div className="w-full flex justify-end mb-2 space-x-2">
            {filteredPeople&&<CSVExporter data = {filteredPeople}/>}
            {filteredPeople&&<button className="border border-green-700 rounded-full px-2 text-green-700 bg-green-50"
              onClick={() => {
                setFilteredPeople(false)
                setPlanFilters([])
                setLegacyPlanFilters([])
              }}
            >Clear Filters</button>}
            <button className="border border-green-700 rounded-full px-2 text-green-700 bg-green-50"
              onClick={() => setFilteredPeople(findConflatedSubscriptions(people))}
            >Find Conflated Subscriptions</button>
            <button className="border border-green-700 rounded-full px-2 text-green-700 bg-green-50"
              onClick={() => setFilteredPeople(findNonMigratedUsers(people))}
            >Find Non-Migrated Users</button>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button onClick={() => setPlanOpen(!planOpen)} className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Filter By Plan
                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-6 w-6 text-gray-400" />
              </Menu.Button>
            </div>

            {planOpen&&<Menu.Items
              static
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5"
            >
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, [getStripePriceId("life")]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes(getStripePriceId("life"))&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Million Dollar Membership
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, [getStripePriceId("tp"),getStripePriceId("tpm"),getStripePriceId("tpl")]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes(getStripePriceId("tp"))&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Million Dollar Road Map
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, [getStripePriceId("proc"),getStripePriceId("procm")]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes(getStripePriceId("proc"))&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Million Dollar Toolkit
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, [getStripePriceId("sbm"),getStripePriceId("sby"),getStripePriceId("sbm1"),]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes(getStripePriceId("sby"))&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Million Dollar Startup
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, ["extraneous"]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes("extraneous")&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Others
                  </button>
                </Menu.Item>
                  <Menu.Item>
                    <button
                    onClick={()=>{
                      setPlanOpen(false)
                      setFilteredPeople(completeFilter(people, planFilters, legacyPlanFilters))
                    }}
                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                    >
                      Apply Filter
                    </button>
                  </Menu.Item>
              </div>
            </Menu.Items>}
          </Menu>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button onClick={() => setLegacyPlanOpen(!legacyPlanOpen)} className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Filter By Legacy Plans
                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-6 w-6 text-gray-400" />
              </Menu.Button>
            </div>

            {legacyPlanOpen&&<Menu.Items
              static
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5"
            >
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={() => setLegacyPlanFilters(toggleElementsInList(legacyPlanFilters, ["life"]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${legacyPlanFilters.includes("life")&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Legacy Million Dollar Membership
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setLegacyPlanFilters(toggleElementsInList(legacyPlanFilters, ["tp"]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${legacyPlanFilters.includes("tp")&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Legacy Agency Training
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setLegacyPlanFilters(toggleElementsInList(legacyPlanFilters, ["proc"]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${legacyPlanFilters.includes("proc")&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Legacy Sales Processes
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setLegacyPlanFilters(toggleElementsInList(legacyPlanFilters, ["sby", "sbm"]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${legacyPlanFilters.includes("sby")&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Legacy Stairs and Buckets
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setLegacyPlanFilters(toggleElementsInList(legacyPlanFilters, ["extraneous"]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${legacyPlanFilters.includes("extraneous")&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Others
                  </button>
                </Menu.Item>
                  <Menu.Item>
                    <button
                    onClick={()=>{
                      setLegacyPlanOpen(false)
                      setFilteredPeople(legacyPlanFilters.length>0?completeFilter(people, planFilters, legacyPlanFilters):false)
                    }}
                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                    >
                      Apply Filter
                    </button>
                  </Menu.Item>
              </div>
            </Menu.Items>}
          </Menu>
          </div>
        <div className="flex justify-between py-2 px-2 font-bold">
        {columns.map((each, k) => 
        <div key = {each} className={`w-1/7 ${k===0?"text-start":"text-center"}`}>{each}</div>
        )}
        </div>

        {(filteredPeople?filteredPeople:people).map((person, k) => (
            <div key={person.id} className={`flex justify-between py-2 px-2 text-sm text-gray-500 ${k %2===0?"bg-green-50":""}`}>
              <div className="text-start flex flex-col">
                <PasswordUpdateButton uid = {person.id}/>
                <EmailUpdateButton uid = {person.id}/>
                {returnTeamModal(person.id, teams, teamsStatus, emailUidPairs)}
                {/* {(teams && teamsStatus.length > 0)&&<TeamEditModal team={ memberPairs = {emailUidPairs}/>} */}
              </div>
            <div className="w-1/6 text-center">{person.name?person.name:"Not Provided"}</div>
            <div className="w-1/6 text-center">{person.email?person.email: "Not Provided"}</div>
            <div className="w-1/6 text-center">{person.phone?person.phone: "Not Provided"}</div>
            <div className="w-1/6 text-center">{person.company?person.company: "Not Provided"}</div>
            <div className="w-1/6 text-center">
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_LIFE)?"Lifetime Membership ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_TP)||person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_TPL)?"Million Dollar Road Map ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_TPM)?"Million Dollar Road Map Monthly ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_PROCM)?"Million Dollar Tool Kit Monthly ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_PROC)?"Million Dollar Tool Kit ":""}
            {(person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_SBM) ||person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_SBY) ||person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_SBM1))?"Million Dollar Stairs and Buckets Start-Up ":""}
            {person.stripePriceIds || person.legacyPlans?"": "No Subscription"}
            {person.legacyPlans?.includes("life")?"Lifetime Membership Legacy ":""}
            {person.legacyPlans?.includes("tp")?"Trainings Package Legacy ":""}
            {person.legacyPlans?.includes("proc")?"Processes Package Legacy ":""}
            {(person.legacyPlans?.includes("sbm") ||person.legacyPlans?.includes('sby'))?"Stairs and Buckets Legacy ":""}
            </div>
            </div>
        ))}
        </div>
        )}
            </LargeCard>
        </div>

        </>
        </div>
    </div>

      </div>
      <SideNav current = "admin"></SideNav>

    </>

  );
}

export default CRMSection;
