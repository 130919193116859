// EbookPage.js
import React from "react";
import { useParams } from "react-router-dom";
import EbookLandingPageSection from "../../components/Custom/EbookLandingPageSection";
import workYourAskOffImage from '../../assets/WorkYourAskOff.jpg';
import sbEbookCoverGraphic from '../../assets/sbEbookCoverGraphic.jpeg';

const ebookConfigs = {
  workyouraskoff: {
    statusKey: "WorkYourAskOff",
    ebookCoverImage: workYourAskOffImage,
    ebookUrl: "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2FWork%20your%20ask%20off%20-%20Agent%20Facing.pdf?alt=media&token=6665541d-a403-4d28-8c30-c12cbac0d6ce",
    pdfAsset: "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2FWork%20your%20ask%20off%20-%20Agent%20Facing.pdf?alt=media&token=6665541d-a403-4d28-8c30-c12cbac0d6ce",
    leadFormZapTags: "has_website_login,ebook_lead_work_your_ask_off",
    leadFormAfterUrl: "/ebooks/workyouraskoff/success",
    headerText: "Thank you for filling out the form",
    descriptionText: "We appreciate your interest! View the PDF below and check your email for a copy.",
    ctaButtonText: "Create An Account",
    emailContent: `
      <p><strong>Thanks for requesting your copy of the Work Your Ask Off ebook!</strong></p>
      <p>This guide dives into the powerful art of asking the right questions to transform your sales conversations.</p>
      <p>Open the attached PDF and start putting these strategies into action today.</p>
      <p><strong>Warm regards,</strong><br/>The Insurance Agency Training Team</p>
    `,
    emailSubject: "Thank You For Your Interest in Our Product!",
    ebookHeader: 'Get Your Free "Work Your Ask Off" Ebook Today!',
    ebookIntro: 'Want to get better results in your sales conversations? We’re offering a free ebook that will show you how to master the art of asking great questions—a skill that helps uncover client needs, build real trust, and guide conversations that lead to action.',
    ebookSubHeader: 'Why Download This Free Ebook?',
    ebookBenefits: [
      'Learn how to ask the right questions that spark meaningful conversations and deeper connections.',
      'Discover proven strategies to communicate the benefits of life insurance effectively.',
      'Use the "Work Your Ask Off" approach to stop telling and start serving—making your process more effective, repeatable, and client-focused.'
    ],
    ebookConclusion: 'The "Work Your Ask Off" method is a game-changer for agents who want to sell more by saying less. By mastering the art of asking better questions, you’ll gain the insight needed to guide your clients confidently—and close more sales without sounding like a sales pitch.',
    ebookCallToAction: 'Download your free ebook now and start transforming your sales process with the "Work Your Ask Off" method!'
  },
  stairsandbuckets: {
    statusKey: "StairsAndBuckets",
    ebookCoverImage: sbEbookCoverGraphic,
    ebookUrl: "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2Febook.pdf?alt=media&token=c2d472b4-9204-4f0e-a819-fa481e68dde4",
    pdfAsset: "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2Febook.pdf?alt=media&token=c2d472b4-9204-4f0e-a819-fa481e68dde4",
    leadFormZapTags: "has_website_login,ebook_lead_stairs_and_buckets",
    leadFormAfterUrl: "/ebooks/stairsandbuckets/success",
    headerText: "Thank you for filling out the form",
    descriptionText: "We appreciate your interest! View the PDF below and check your email for your free ebook.",
    ctaButtonText: "Create An Account",
    emailContent: `
      <p>Thank you for signing up to receive the <em>Stairs & Buckets</em> ebook!</p>
      <p>If you're ready to take your insurance sales to the next level, this ebook is exactly what you need.</p>
      <p>Start reading today by opening the attached PDF.</p>
      <p><strong>Best regards,</strong><br/>Insurance Agency Training Team</p>
    `,
    emailSubject: "Thank You For Your Interest in Our Product!",
    ebookHeader: 'Get Your Free "Stairs & Buckets" Ebook Today!',
    ebookIntro: 'Want to make your insurance sales more effective and easier for clients to understand? We’re offering a free ebook that will show you how to use the "Stairs & Buckets" system—a straightforward and proven approach to explaining term and whole life insurance. This system helps you build trust with clients, close more sales, and create a consistent, repeatable sales process.',
    ebookSubHeader: 'Why Download This Free Ebook?',
    ebookBenefits: [
      'Learn how to clearly explain both term and whole life insurance so your clients understand the value of each type.',
      'Discover proven strategies to communicate the benefits of life insurance effectively.',
      'Use the "Stairs & Buckets" system to make your sales process impactful, easy to repeat, and effective for your entire team.'
    ],
    ebookConclusion: 'The "Stairs & Buckets" system is the key to making insurance sales simpler and more successful—not just for you, but for your whole team. This free ebook will guide you step-by-step on how to use this approach to transform your sales.',
    ebookCallToAction: 'Get your free ebook now and start transforming your sales process with the "Stairs & Buckets" system!'
  }
};

const EbookPage = () => {
  const { ebookSlug, status } = useParams();
  const config = ebookConfigs[ebookSlug];

  if (!config) {
    return <div>Invalid ebook requested.</div>;
  }

  return (
    <EbookLandingPageSection
      statusKey={config.statusKey}
      currentStatus={status} // should be "optin" or "success"
      ebookCoverImage={config.ebookCoverImage}
      ebookUrl={config.ebookUrl}
      leadFormZapTags={config.leadFormZapTags}
      leadFormAfterUrl={config.leadFormAfterUrl}
      headerText={config.headerText}
      descriptionText={config.descriptionText}
      ctaButtonText={config.ctaButtonText}
      emailContent={config.emailContent}
      emailSubject={config.emailSubject}
      ebookHeader={config.ebookHeader}
      ebookIntro={config.ebookIntro}
      ebookSubHeader={config.ebookSubHeader}
      ebookBenefits={config.ebookBenefits}
      ebookConclusion={config.ebookConclusion}
      ebookCallToAction={config.ebookCallToAction}
      pdfAsset={config.pdfAsset}
    />
  );
};

export default EbookPage;
