import React, { useState, useEffect } from 'react';
import { useAuth } from '../../util/auth';
import { updateUser, useUser, createNotification } from '../../util/db';
import LoadingIcon from '../LoadingIcon';
import { Link, useRouter } from '../../util/router';
import SettingsPassword from '../SettingsPassword';
import FormAlert from '../FormAlert';
import { handleZapier } from "../../util/zapier";


const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [success, setSuccess] = useState(false)
  // const [emailVerified, setEmailVerified] = useState(false)
  const [formAlert, setFormAlert] = useState(null);
  const [codeResent, setCodeResent] = useState(false);
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
  });
  const auth = useAuth()
  const router = useRouter()
  const {
    data: userData,
    error: userDataError,
    status: userDataStatus
  } = useUser(auth?.user?.uid)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function postZap(name, company, phone_number ) {
    const resp =  await  handleZapier("postCustomer", {"full_name":name, "phone_number":phone_number, "company": company,"email":auth.user.email, "tags": "has_website_login"})
   }

  async function postZapImport() {
    const resp =  await  handleZapier("updateImport", {"email":auth.user.email})
   }

  const handleSendVerificationEmail = async () => {
    try {
      await auth.sendVerificationEmail();
      console.log('Verification email sent!');
    } catch (error) {
      console.error('Error sending verification email:', error);
      alert('Failed to send verification email.');
    }
  };

  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
      if (type === "success") {
        updateUser(auth.user.uid, {importResolutionRequired: false})
      }
    }
  };


  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

   async function handleSubmit() {
    // Process formData here
    setSubmitDisabled(true)
    setCurrentStep(currentStep+1)
    await postZap(formData.name, formData.company, formData.phone)
    if (auth.user.legacyPlans?.length>0){
      await postZapImport()
    }

    await createNotification(
      {
        owner: auth.user.uid,
        allow: true,
        exclusive_email: true,
        exclusive_sms: true,
        training_email: true,
        training_sms: true
      }
    )
    await updateUser(auth.user.uid, {
        completeInfo: true,
        ...formData})
    setSuccess(true)
};

// useEffect(() => {
//   if (auth.user?.emailVerified) {
//     setEmailVerified(auth.user.emailVerified);
//   }
// }, [auth.user]);

  return (
    <div className="flex justify-center">
      <div className="bg-white p-10 rounded-lg border w-2/3">
        {(userData && userDataStatus==="success" && (!userData.completeInfo))&&(<>
        {/* {(auth.user.emailVerified)? */}
        <>
        {(currentStep<4)&&(<>
            <h1 className="text-2xl font-bold mb-6 text-green-800">Great! We just need a few more pieces of information from you...</h1>
            <p className="mb-8 text-gray-700">
            Please fill in the following information. The form will guide you through multiple steps to complete the process.
            </p>
        </>)}
        {(currentStep === 1 && auth.user.importResolutionRequired) && (
            <div className="slide">
              <h2 className="text-xl font-semibold mb-4">First, set your new password.</h2>
              {<>
                <>
                {formAlert && (
                  <div className="mb-4">
                    <FormAlert type={formAlert.type} message={formAlert.message} />
                  </div>
                )}
                </>
              <SettingsPassword onStatus = {handleStatus}/>
              </>
              }
            
            </div>
          )}
        <form>
          {(currentStep === 1 && !auth.user.importResolutionRequired) && (
            <div className="slide">
              <h2 className="text-xl font-semibold mb-4">Step 1: Enter your Name</h2>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full Name"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <div className="flex justify-end">
                <button type="submit" onClick={handleNext} className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
                  Next
                </button>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div className="slide">
              <h2 className="text-xl font-semibold mb-4">Step 2: Enter your Company</h2>
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                placeholder="Your Company"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <div className="flex justify-between">
                <button type="button" onClick={handlePrevious} className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600">
                  Previous
                </button>
                <button type="submit" onClick={handleNext} className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
                  Next
                </button>
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div className="slide">
              <h2 className="text-xl font-semibold mb-4">Step 3: Enter your Cell Phone Number</h2>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="(XXX) XXX-XXX"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                required
              />
              <div className="flex justify-between">
                <button type="button" onClick={handlePrevious} className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600">
                  Previous
                </button>
                <button onClick={()=> {handleSubmit()}} disabled={submitDisabled} type="submit" className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
                  Submit
                </button>
              </div>
            </div>
          )}
        </form>

        {(currentStep === 4 && success===false) && (
            <div className='flex w-full justify-center'>
            <LoadingIcon className="h-6"/>
            </div>
        )}
        {/* {(!emailVerified&&<div>
        <h4 className="sr-only">Status</h4>
        <div className="mt-6" aria-hidden="true">
            <div className="overflow-hidden rounded-full bg-gray-200">
            <div className="h-2 rounded-full bg-green-600"
                style={{
                    width: `${(currentStep-1)*100/3}%`,
                    transition: 'width 0.5s ease-in-out'
                }}/>
            </div>

        </div>
        </div>)} */}
        </>
        {/* : */}
        {/* <>
        <h1 className="text-2xl font-bold mb-6 text-green-800">Great! We just sent you an email.</h1>
            <p className="mb-8 text-gray-700">
            Please open the link we sent to verify your email address. Then we will continue and collect some information from you!
            </p>
            <div className='flex flex-row space-x-4'>
            <button className= 'bg-green-700 text-white w-full p-2 rounded-lg'onClick = {() => {window.location.reload()}}>Continue Once Verified</button>
            <button className='text-green-700 border border-green-700 w-full p-2 rounded-lg' disabled={codeResent} onClick = {() => {
              setCodeResent(true)
              handleSendVerificationEmail()
              }}>Resend Code</button>
            </div>
        </>} */}
        </>
        )}


        {(currentStep === 4 && success===true) && (
            <div className='text-center'>
            <h1 className="text-2xl font-bold mb-6 text-green-800">Great! You're All Set!</h1>
            <p className="mb-8 text-gray-700">
            Click the button below to get started.
            </p>
            <Link to={router.query.next?router.query.next:"/tools/ip"}>
            <button className='w-full bg-green-600 rounded-lg text-white text-xl p-4'>Get Started!</button>
            </Link>
            </div>
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;
