import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebaseApp } from "./firebase";

// Initialize Firestore
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), {createdAt: serverTimestamp(), ...data}, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}


//  CUSTOM FUNCTIONS BELOW HERE!!!

export function useCodeOnce(id) {
  return useQuery(
    ["code", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "video_codes", id)).then(format),
    { enabled: !!id }
  );
}

export function useCodesByOwner(owner) {
  return useQuery(
    ["video_codes", owner],
    createQuery(() =>
      query(
        collection(db, "video_codes"),
        where("owner","==",owner),
        orderBy("createdAt", "desc")
      )
    ),
  );
}

export function createCode(hash, data) {
  return setDoc(doc(db, "video_codes", hash), {
    
    ...data, 
    createdAt: serverTimestamp()
  }, { merge: true });
}
export function updateCode(id, data) {
  return updateDoc(doc(db, "video_codes", id), data);
}

export function useVideoByIndex(index) {
  return useQuery(
    ["video_embeds", { index }],
    createQuery(() =>
      query(
        collection(db, "video_embeds"),
        where("index", "==", index),
      )
    ),
    { enabled: index !== undefined && index !== null }
  );
}

export function useVideoById(id) {
  return useQuery(
    ["video_embeds", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "video_embeds", id)).then(format),
    { enabled: !!id }
  );
}

export function useAllVideos() {
  return useQuery(
    ["video_embeds"],
    createQuery(() =>
      query(
        collection(db, "video_embeds"),
        where("index", "!=", 0),
        orderBy("index", "asc")
      )
    ),
  );
}

export function useAllTeams() {
  return useQuery(
    ["teams"],
    createQuery(() =>
      query(
        collection(db, "teams"),
        orderBy("owner", "asc")
      )
    ),
  );
}

export function useAllBlogPosts() {
  return useQuery(
    ["blog_posts"],
    createQuery(() =>
      query(
        collection(db, "blog_posts"),
        orderBy("createdAt", "desc")
      )
    ),
  );
}

export function useBlogPostOnce(id) {
  return useQuery(
    ["blog_posts", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "blog_posts", id)).then(format),
    { enabled: !!id && id !== "all" }
  );
}

export function useNotifications(owner) {
  return useQuery(
    ["notification_settings"],
    createQuery(() =>
      query(
        collection(db, "notification_settings"),
        where("owner","==",owner),
      )
    ),
  );
}

export function updateNotifications(id, data) {
  return updateDoc(doc(db, "notification_settings", id), data);
}

export function createNotification(data) {
  return addDoc(collection(db, "notification_settings"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}


export function useTeam(id) {
  return useQuery(
    ["teams", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "teams", id)).then(format),
    { enabled: !!id }
  );
}

export function useOwnedTeams(uid) {
  return useQuery(
    ["teams"],
    createQuery(() =>
      query(
        collection(db, "teams"),
        where("owner","==",uid),
      )
    ),
  );
}

export function useTeamsByMembership(uid) {
  return useQuery(
    ["teams"],
    createQuery(() =>
      query(
        collection(db, "teams"),
        where("members","array-contains",uid),
      )
    ),
  );
}

export function createTeam(data) {
  return addDoc(collection(db, "teams"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function updateTeam(id, data) {
  return updateDoc(doc(db, "teams", id), data);
}

export function deleteTeam(id) {
  return deleteDoc(doc(db, "teams", id));
}

export function useUserByEmail(email) {
  return useQuery(
    ["users", { email }],
    createQuery(() =>
      query(
        collection(db, "users"),
        where("email", "==", email),
      )
    ),
    { enabled: !!email }
  );
}

export function createInvitation(data) {
  return addDoc(collection(db, "invitations"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function useInvitationsByOwnerId(id) {
  return useQuery(
    ["invitations"],
    createQuery(() =>
      query(
        collection(db, "invitations"),
        where("owner","==",id),
        orderBy("createdAt", "desc")
      )
    ),
  );
}

export function useInvitationsByOwnerEmail(email) {
  return useQuery(
    ["invitationsUnsec"],
    createQuery(() =>
      query(
        collection(db, "invitations"),
        where("owner","==",email),
        where("secure", "==", false), // Add this line
        orderBy("createdAt", "desc")
      )
    ),
  );
}

export function useInvitationsBySender(id) {
  return useQuery(
    ["invitationsUnsec"],
    createQuery(() =>
      query(
        collection(db, "invitations"),
        where("sender","==",id),
        orderBy("createdAt", "desc")
      )
    ),
  );
}

export function deleteInvitation(id) {
  return deleteDoc(doc(db, "invitations", id));
}

export function useUserOnce(id) {
  return useQuery(
    ["user", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "users", id)).then(format),
    { enabled: !!id }
  );
}

export function useAllUsers() {
  return useQuery(
    ["users"],
    createQuery(() =>
      query(
        collection(db, "users"),
      )
    ),
  );
}

export function createTask(data) {
  return addDoc(collection(db, "tasks"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}


export function useTasksByOwner(owner) {
  return useQuery(
    ["tasks"],
    createQuery(() =>
      query(
        collection(db, "tasks"),
        where("owner","==",owner),
      )
    ),
  );
}

export function useAllPDFs() {
  return useQuery(
    ["pdf_embeds"],
    createQuery(() =>
      query(
        collection(db, "pdf_embeds"),
      )
    ),
  );
}

export function usePDFsByCategory(category) {
  return useQuery(
    ["pdf_embeds", category],
    createQuery(() =>
      query(
        collection(db, "pdf_embeds"),
        where("linkedResources","array-contains",category),
      )
    ),
  );
}

export function deleteTask(id) {
  return deleteDoc(doc(db, "tasks", id));
}

export function useAllTestimonials() {
  return useQuery(
    ["user_testimonials"],
    createQuery(() =>
      query(
        collection(db, "user_testimonials"),
        where("approved", "==", true),
        orderBy("createdAt", "desc")
      )
    ),
  );
}

export function useUnapprovedTestimonials() {
  return useQuery(
    ["user_testimonials"],
    createQuery(() =>
      query(
        collection(db, "user_testimonials"),
        where("approved", "==", false),
        orderBy("createdAt", "desc")
      )
    ),
  );
}

export function createTestimonial(data) {
  return addDoc(collection(db, "user_testimonials"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function deleteTestimonial(id) {
  return deleteDoc(doc(db, "user_testimonials", id));
}

export function updateTestimonial(id, data) {
  return updateDoc(doc(db, "user_testimonials", id), data);
}

export function updateLiveLink(id, data) {
  return updateDoc(doc(db, "live_links", id), data);
}

export function createLiveLink(data) {
  return addDoc(collection(db, "live_links"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function useLiveLink(id) {
  return useQuery(
    ["link", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "live_links", id)).then(format),
    { enabled: !!id }
  );
}

export function useLiveLinks() {
  const oneDayAgo = new Date();
  oneDayAgo.setDate(oneDayAgo.getDate() - 1);
  return useQuery(
    ["live_links"],
    createQuery(() =>
      query(
        collection(db, "live_links"),
        where("finishTime",">",oneDayAgo),
        orderBy("finishTime", "asc")
      )
    ),
  );
}

export function useSupportThreads(uid) {
  return useQuery(
    ["support_threads"],
    createQuery(() =>
      query(
        collection(db, "support_threads"),
        where("owner","==",uid),
        orderBy("createdAt", "desc")
      )
    ),
  );
}

export function createSupportThread(data) {
  return addDoc(collection(db, "support_threads"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function createSupportForm(data) {
  return addDoc(collection(db, "support_forms"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function createLiveWebinar(data) {
  return addDoc(collection(db, "live_webinars"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function useLiveWebinarsByURLSuffix(suffix) {
  return useQuery(
    ["live_webinars", { suffix }],
    createQuery(() =>
      query(
        collection(db, "live_webinars"),
        where("linkedResources", "array-contains", suffix),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!suffix }
  );
}

export function useLiveWebinarById(id) {
  return useQuery(
    ["live_webinars", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "live_webinars", id)).then(format),
    { enabled: !!id }
  );
}

export function deleteSupportThread(id) {
  return deleteDoc(doc(db, "support_threads", id));
}

export function useUsersByIds(ids) {
  return useQuery(
    ["users", { ids }],
    createQuery(() =>
      query(
        collection(db, "users"),
        where("__name__", "in", ids),
      )
    ),
    { enabled: !!ids }
  );
}

///For Documents

export const getPdfUrl = async (fileName) => {
  try {
    const pdfRef = ref(storage, `resources/${fileName}`);
    const url = await getDownloadURL(pdfRef);
    return url;
  } catch (error) {
    console.error("Error fetching PDF:", error);
    throw error;
  }
};


const uploadPhoto = async (file, userId, pathPref) => {
  if (!file) {
    throw new Error("No file provided");
  }

  const storageRef = ref(storage, `${pathPref}${userId}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Handle progress if needed
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        // Handle error
        reject(error);
      },
      async () => {
        // Handle successful upload
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadURL);
      }
    );
  });
};

const savePhotoURLToFirestore = async (userId, photoURL) => {
  const userDocRef = doc(db, 'users', userId);
  await updateDoc(userDocRef, { profilePhoto: photoURL });
};

const saveTeamPhotoToFirestore = async (teamId, photoURL) => {
  const userDocRef = doc(db, 'teams', teamId);
  await updateDoc(userDocRef, { profilePhoto: photoURL });
};

export const handleFileUpload = async (file, userId) => {
  try {
    const photoURL = await uploadPhoto(file, userId, "profilePhotos/");
    await savePhotoURLToFirestore(userId, photoURL);
    console.log("Photo uploaded and URL saved to Firestore successfully");
  } catch (error) {
    console.error("Error uploading photo: ", error);
  }
};


export const handleTeamPhotoPost = async  (file, teamId) => {
  try {
    const photoURL = await uploadPhoto(file, teamId, "teamPhotos/");
    await saveTeamPhotoToFirestore(teamId, photoURL);
    console.log("Photo uploaded and URL saved to Firestore successfully");
  } catch (error) {
    console.error("Error uploading photo: ", error);
  }
}

// async function getDownloadURLFromStoragePath(storagePath) {
//   try {
//     const storageRef = ref(storage, storagePath);
//     const downloadURL = await getDownloadURL(storageRef);
//     console.log("Download URL:", downloadURL);
//   } catch (error) {
//     console.error("Error getting download URL:", error);
//   }
// };

// getDownloadURLFromStoragePath("blogPosts/blog_19.md")
