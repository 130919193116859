// EbookLandingPageSection.js
import React from 'react';
import { useAuth } from '../../util/auth';
import LeadForm from './LeadForm';
import PDFView from './PDFView';
import { Link } from '../../util/router';

const EbookLandingPageSection = ({
  currentStatus, // "optin" or "success"
  ebookUrl,      // used in success view (PDFView)
  leadFormZapTags,
  leadFormAfterUrl,
  headerText,
  descriptionText,
  ctaButtonText,
  emailContent,
  emailSubject,
  ebookCoverImage,
  // Dynamic content for opt-in right column:
  ebookHeader,
  ebookIntro,
  ebookSubHeader,
  ebookBenefits,
  ebookConclusion,
  ebookCallToAction,
  pdfAsset     // PDF for LeadForm (optin)
}) => {
  const auth = useAuth();

  return (
    <div className='container mx-auto px-4'>
      {currentStatus === "optin" && (
        <div className="flex flex-col md:flex-row md:space-x-2 md:divide-x">
          {/* On mobile, the ebook info appears first */}
          <div className="w-full md:w-2/3 order-1 md:order-2 p-2">
            <div className="flex flex-col md:flex-row">
              <div className="mb-4 md:mb-0">
                <h2 className="text-base font-semibold leading-7 text-green-800">
                  {ebookHeader}
                </h2>
                <p className="text-sm leading-6 text-gray-600">
                  {ebookIntro}
                </p>
                <br />
                <h2 className="text-base font-semibold leading-7 text-green-800">
                  {ebookSubHeader}
                </h2>
                <ul className="list-disc pl-5">
                  {ebookBenefits && ebookBenefits.map((benefit, index) => (
                    <li key={index} className="text-sm leading-6 text-gray-600">
                      {benefit}
                    </li>
                  ))}
                </ul>
                <br />
              </div>
              <div className="flex justify-center">
                <img
                  src={ebookCoverImage}
                  className="w-full md:w-auto h-auto md:h-400 object-contain"
                  alt="Ebook Cover"
                />
              </div>
            </div>
            <p className="text-sm leading-6 text-gray-600 mt-4">
              {ebookConclusion}
            </p>
            <p className="text-sm leading-6 text-gray-600">
              <strong>{ebookCallToAction}</strong>
            </p>
          </div>
          {/* LeadForm appears below the ebook info on mobile */}
          <div className="w-full md:w-1/3 order-2 md:order-1 space-y-6">
            <LeadForm
              zapTags={leadFormZapTags}
              afterUrl={leadFormAfterUrl}
              pdfAsset={pdfAsset}
              emailContent={emailContent}
              emailSubject={emailSubject}
            />
          </div>
        </div>
      )}

      {currentStatus === "success" && (
        <div className="min-h-screen bg-white flex flex-col items-center px-4">
          {/* Header Section */}
          <h1 className="text-3xl md:text-4xl font-bold text-black mt-16 mb-6 text-center">
            {headerText}
          </h1>
          <p className="text-base md:text-lg text-gray-700 mb-12 text-center max-w-2xl">
            {descriptionText}
          </p>
          {/* Download Button */}
          <div className="mb-8">
            <a
              href={ebookUrl}
              download
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-3 px-8 rounded-md shadow-md"
            >
              Download The Ebook
            </a>
          </div>
          {/* PDF Embed Section */}
          <div className="w-full max-w-3xl mb-16">
            <PDFView pdf={ebookUrl} />
          </div>

          {/* Next Steps Section */}
          <div className="w-full max-w-4xl mb-16">
            <h2 className="text-xl md:text-2xl font-semibold text-black mb-6">Next Steps</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
              <div className="flex flex-col items-center text-center p-4">
                <div className="h-16 w-16 md:h-20 md:w-20 bg-green-700 text-white rounded-full flex items-center justify-center mb-2">
                  <span className="text-xl md:text-2xl font-bold">1</span>
                </div>
                <h3 className="text-lg md:text-xl font-semibold mb-1">Read the Guide</h3>
                <p className="text-sm md:text-base text-gray-600">
                  Check out the comprehensive guide in the PDF to understand all the features we offer.
                </p>
              </div>
              <div className="flex flex-col items-center text-center p-4">
                <div className="h-16 w-16 md:h-20 md:w-20 bg-green-700 text-white rounded-full flex items-center justify-center mb-2">
                  <span className="text-xl md:text-2xl font-bold">2</span>
                </div>
                <h3 className="text-lg md:text-xl font-semibold mb-1">Create an Account</h3>
                <p className="text-sm md:text-base text-gray-600">
                  Sign in and then select a plan to start using Stairs & Buckets.
                </p>
              </div>
              <div className="flex flex-col items-center text-center p-4">
                <div className="h-16 w-16 md:h-20 md:w-20 bg-green-700 text-white rounded-full flex items-center justify-center mb-2">
                  <span className="text-xl md:text-2xl font-bold">3</span>
                </div>
                <h3 className="text-lg md:text-xl font-semibold mb-1">Get Started</h3>
                <p className="text-sm md:text-base text-gray-600">
                  Begin your journey with us and enjoy the benefits of our premium services.
                </p>
              </div>
            </div>
          </div>

          {/* Call-to-Action Button */}
          <Link to={auth.user ? "/pricing/plans" : "/auth/signup"}>
            <button className="bg-green-700 hover:bg-green-800 text-white font-semibold py-3 px-8 rounded-md shadow-md mb-16">
              {ctaButtonText}
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default EbookLandingPageSection;
