import { Link } from "../../util/router"
import LeadForm from "./LeadForm"
import TitleCard from "./TitleCard"
import VideoTestimonialCard from "./VideoTestimonialCard"


const stats = [
  { label: 'Results', value: 'Proven' },
  { label: 'Active Users', value: '1,000+' },
  { label: 'Progress Tracking', value: 'Real Time' },
]
const values = [
  {
    name: "Vision: Changing America's Financial Mindset",
    description:
    'We envision changing the way America looks at money. Our goal is to tackle the "microwave mentality" that demands instant gratification. This mindset has led many to live paycheck to paycheck, struggling to stay afloat. By promoting financial discipline and long-term planning, we aim to foster a culture where individuals learn to live within their means.'
  },
  {
    name: 'Purpose: Equipping Agents for Smart Financial Guidance',
    description:
    "Our purpose is to equip insurance agents with the necessary tools and talking points to help their customers make wise financial decisions. We focus on providing agents with the skills to guide their clients in protecting and growing their wealth. This dual approach ensures that agents can effectively support their clients' financial well-being."
  },
  {
    name: 'Core Competencies: Wealth Protection and Growth',
    description:
    "We excel in two primary areas: protecting and growing wealth. Our training programs are designed to enhance agents' abilities in these crucial aspects. By helping customers view money differently and save smarter, our agents can make a significant impact on their clients' financial health."
  },
  {
    name: 'Tools for Efficiency and Profitability',
    description:
      "Our tools are designed to enable agents to sell efficiently and at high levels. By using our resources, agents can solve more customer problems and align their own financial management with their clients' needs. This dual benefit helps agents work more efficiently and increase their income significantly.",
  },
  {
    name: 'Simple and Actionable Training',
    description:
      'We emphasize simplicity in our training tools. Complex issues often lead to analysis paralysis, so we keep our messages and tools straightforward. This approach ensures that both agents and their team members can quickly understand and act on the training, leading to better outcomes for everyone involved.',
  },
  {
    name: 'Proven, Repeatable Processes',
    description:
      'Our methods have been tested and refined over two decades, proving effective across various demographics and locations. These processes are designed to be repeatable and independent of individual team members, ensuring consistent results regardless of staff changes. This stability allows teams to maintain high performance over time.',
  },
]

const testimonials = [
  {
    id: 1,
    title: '',
    plan: "sby",
    name: ' David Peterson',
    embed: "982195598",
  },
  {
    id: 2,
    title: '',
    plan: "sby",
    name: 'Colter Johnson',
    embed: "982195521",
  },  
  {
    id: 3,
    title: '',
    plan: "sby",
    name: 'Scott Foster',
    embed: "982203270",
  },
  
  // More posts...
]

export default function CustomLandingSection() {

  return (
    <div className="bg-white">
      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10">
          <svg
            aria-hidden="true"
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" width="100%" height="100%" strokeWidth={0} />
          </svg>
          <div
            aria-hidden="true"
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
          >
            <div
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#006400] to-[#006400] opacity-50"
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-green-700 sm:text-6xl">
                    Insurance Agency Training
                  </h1>
                  <h2 className="mt-6 text-xl font-bold leading-8 text-gray-600">
                  Powered By Stairs and Buckets
                  </h2>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  We are trusted across the Industry. We partner with insurance agents to drive large sales by providing effective tools that are easy to use and unique to insurance agents.
                </p>
                <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                These unique tools can be used both trainers, agents brokers and companies as a training system or as well as customer facing tools for sales appointments with consumer.
                  </p>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">The 4 minute Stairs and Buckets video for Life Insurance agents is just one of our tools and has made agents hundreds of thousands of dollars and is so simple and effective that kids in middle school and high school get it.</p>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  Insurance Agency Training as a company understands the challenges insurance agents and provide you with the resources you need to succeed.  
                  </p>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  Join us and take the step towards transforming your personal wealth today.
                  </p>
                </div>
                <div className="mt-14 justify-end gap-8 sm:-mt-44 sm:justify-start hidden sm:flex sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        alt=""
                        src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=528&q=80"
                        
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        alt=""
                        src={"https://images.unsplash.com/photo-1519634848-ee94122c286c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGJ1c2luZXNzJTIwbWFufGVufDB8fDB8fHww"}
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        alt=""
                        src={"https://images.unsplash.com/photo-1606836591695-4d58a73eba1e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YnVzaW5lc3MlMjBtZWV0aW5nfGVufDB8fDB8fHww"}
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        alt=""
                        src={"https://images.unsplash.com/photo-1450101499163-c8848c66ca85?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y29udHJhY3R8ZW58MHx8MHx8fDI%3D"}
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        alt=""
                        src={"https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGZpbmFuY2UlMjBidWlsZGluZ3xlbnwwfHwwfHx8MA%3D%3D" + "&auto=format&fit=crop&h=528&q=80"}
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-green-700 sm:text-4xl">What we do</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <h3 className="text-xl leading-8 text-gray-600">
                Elevate agents skill level and sales with our expert tools, webinars, sales scripts and books that you can master and copy and use. We tell people to master what is in the box so they can think outside the box.
                </h3>
                <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                  <p>
                  The Stairs and Buckets system offers an approach to life insurance sales, simplifying complex concepts and getting results.
                  </p>
                </div>
              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                      <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-green-700">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Image section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-green-700 sm:text-4xl">Want a Free Ebook?</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Click below to fill out the form and get your own copy of the free ebook explaining "Stairs and Buckets" in greater detail.
            </p>
          </div>
          <Link to="/ebooks/stairsandbuckets/optin">
        <button className="bg-green-700 w-full text-3xl text-white rounded-lg p-3 mt-3">Get your Free Ebook</button>
        </Link>
        </div>

        {/* <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            alt=""
            src="https://images.unsplash.com/photo-1521791136064-7986c2920216?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8aGFuZHNoYWtlfGVufDB8fDB8fHww"
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          />
        </div> */}

        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-green-700 sm:text-4xl">Hear from real customers</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Our customers are more than satisfied! Hear what they have to say!
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none md:grid-cols-2 lg:grid-cols-3">
            {testimonials.map((testimonial) => (
              <div key = {testimonial.embed}>
                <VideoTestimonialCard title = {testimonial.title} embed = {testimonial.embed} display_name = {testimonial.name} plan = {testimonial.plan}/>
              </div>
            ))}
          </div>

        </div>

        
        {/* Logo cloud */}
        {/* <div className="relative isolate -z-10 mt-32 sm:mt-48">
          <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
            <svg aria-hidden="true" className="h-[40rem] w-[80rem] flex-none stroke-gray-200">
              <defs>
                <pattern
                  x="50%"
                  y="50%"
                  id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                  width={200}
                  height={200}
                  patternUnits="userSpaceOnUse"
                  patternTransform="translate(-100 0)"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
                <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" strokeWidth={0} />
              </svg>
              <rect fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)" width="100%" height="100%" strokeWidth={0} />
            </svg>
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
              Trusted by the world’s most innovative teams
            </h2>
            <div className="mx-auto mt-10 grid grid-cols-2 items-center gap-x-8 gap-y-10 sm:grid-cols-4 lg:grid-cols-6 w-full">
              <img
                alt="Speed"
                src={speed}
                className="col-span-1 max-h-24 w-full object-contain"
              />
              <img
                alt="Coach"
                src={coach}
                className="col-span-1 max-h-24 w-full object-contain"
              />
              <img
                alt="FCG"
                src={fcg}
                className="col-span-1 max-h-24 w-full object-contain"
              />
              <img
                alt="Today"
                src={today}
                className="col-span-1 max-h-24 w-full object-contain"
              />
              <img
                alt="Risk"
                src={risk}
                className="col-span-1 max-h-24 w-full object-contain"
              />
              <img
                alt="Auto"
                src={auto}
                className="col-span-1 max-h-24 w-full object-contain"
              />
            </div>
          </div>
        </div> */}


      </main>

    </div>
  )
}
